import { isEmpty } from "lodash";
import moment from "moment";

import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { PackageType } from "@lib/data/schemas/packages";
import { getAppointmentDuration } from "@lib/utils/appointments";
import { momentDate } from "@lib/utils/todos";

type PackageCycleType = PackageType | PackageInstanceType;

type IsCycleFullProps = {
  packageData: PackageCycleType;
  cycle: number;
  packageAppointments: AppointmentType[];
  newAppointment?: AppointmentType;
};

const isCycleFull = ({
  packageData,
  cycle,
  packageAppointments,
  newAppointment,
}: IsCycleFullProps) => {
  const cycleAppointments = packageAppointments?.filter(
    (a) => a.packageInstanceCycle === cycle
  );
  const allAppointments = newAppointment
    ? [...cycleAppointments, newAppointment]
    : cycleAppointments;

  if (isEmpty(allAppointments)) return false;

  const { maxMinutes, maxSessions } = getPackageCycleAmounts(
    packageData,
    undefined,
    cycle
  );

  if (maxMinutes) {
    const currentCycleMinutes = cycleAppointments.reduce((acc, appt) => {
      const duration = getAppointmentDuration(appt);
      return acc + duration;
    }, 0);
    const hypotheticalCycleMinutes = allAppointments.reduce((acc, appt) => {
      const duration = getAppointmentDuration(appt);
      return acc + duration;
    }, 0);
    return (
      currentCycleMinutes >= maxMinutes || hypotheticalCycleMinutes > maxMinutes
    );
  }
  if (maxSessions) {
    const cycleSessions = allAppointments.length;
    return cycleSessions > maxSessions;
  }
  return false;
};

function isPackageInstanceType(
  packageData: PackageCycleType
): packageData is PackageInstanceType {
  return (packageData as PackageInstanceType).startDate !== undefined;
}

export const getCycleCountPackage = (
  packageData: PackageCycleType,
  startDate: string | Date
) => {
  if (!packageData.frequency) return 1;

  if (isPackageInstanceType(packageData)) return packageData.currentCycle ?? 1;

  const now = moment();
  const start = momentDate(startDate);
  if (start > now) return 1;

  const type = packageData.frequency?.type;
  const differenceInTime = now.diff(start, type, true);
  const cycle = Math.abs(Math.ceil(differenceInTime));
  if (cycle === 0) return 1;
  return cycle;
};

export const getCycleDates = (
  packageData: PackageCycleType,
  startDate: string,
  existingAppointments?: AppointmentType[],
  appointmentToAdd?: AppointmentType
) => {
  const { frequency } = packageData;
  const now = moment();
  let cycleStart = momentDate(startDate);
  let cycleEnd = null;

  const cycles = [];
  const cycleCount = getCycleCountPackage(packageData, startDate);
  const type = frequency?.type === "weeks" ? "Week" : "Month";

  const getCycleEnd = (cycle: number) => {
    if (
      isPackageInstanceType(packageData) &&
      packageData.cyclePauses?.[cycle]
    ) {
      return moment(packageData.cyclePauses[cycle].at(-1)?.cycleEndDate);
    }

    return cycleStart.clone().add(1, frequency?.type);
  };

  for (let i = 0; i < cycleCount; i++) {
    cycleEnd = getCycleEnd(i + 1);
    cycles.push({
      cycleLabel: `${type} ${i + 1}`,
      isCurrent: now.isBetween(cycleStart, cycleEnd),
      dates: `${cycleStart.format("MMM DD")} - ${cycleEnd.format("MMM DD")}`,
      start: cycleStart.toDate(),
      end: cycleEnd.toDate(),
      cycle: i + 1,
      ...(existingAppointments && {
        full: packageData.rollOver
          ? false
          : isCycleFull({
              packageData,
              cycle: i + 1,
              packageAppointments: existingAppointments,
              newAppointment: appointmentToAdd,
            }),
      }),
    });
    cycleStart = cycleEnd;
  }

  return cycles;
};

export const getPackageCycleAmounts = (
  packageData: PackageCycleType,
  startDate?: string | Date,
  cycle?: number
) => {
  if (!packageData)
    return {
      maxMinutes: null,
      maxSessions: null,
      cycleCount: 1,
    };
  const { frequency, contentType, timeType, totalSessions, items } =
    packageData;

  const cycleCount = !startDate
    ? 1
    : getCycleCountPackage(packageData, startDate);

  if (contentType === "sessions") {
    // non-recurring sessions
    if (!frequency) {
      // older packages might not have totalSessions
      if (!totalSessions) {
        const total = items.reduce((acc, item) => {
          return acc + item.quantity;
        }, 0);
        return {
          maxSessions: total,
          maxMinutes: null,
          cycleCount,
        };
      }
      return {
        maxSessions: totalSessions,
        maxMinutes: null,
        cycleCount,
      };
    }

    const cycleExtension = cycle
      ? (packageData as PackageInstanceType)?.cycleExtensions?.[cycle] || 0
      : 0;

    // recurring sessions
    const maxSessions = frequency?.total + cycleExtension ?? 1;
    return {
      maxSessions,
      maxMinutes: null,
      cycleCount,
    };
  }

  if (contentType === "time") {
    // non-recurring time
    if (!frequency) {
      const maxMinutes =
        timeType === "minutes" ? totalSessions : (totalSessions ?? 1) * 60;
      return {
        maxMinutes: maxMinutes ?? null,
        maxSessions: null,
        cycleCount,
      };
    }

    // recurring time
    const maxMinutes =
      timeType === "minutes" ? frequency?.total : (frequency?.total ?? 1) * 60;
    return {
      maxMinutes: maxMinutes ?? null,
      maxSessions: null,
      cycleCount,
    };
  }

  return {
    maxMinutes: null,
    maxSessions: null,
    cycleCount,
  };
};
