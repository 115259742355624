import { useMemo } from "react";
import { Calendar, CalendarIntegration } from "@practice/sdk";

import { useAuth } from "@contexts/auth";
import { getAllCalendarAccounts } from "@lib/utils/google-calendars";

type UseGetGoogleCalendarsResponse = {
  accounts: CalendarIntegration[];
  defaultWriteCalendar: Calendar | null;
};

type UseGetGoogleCalendarsType = (
  accountOnly?: boolean
) => UseGetGoogleCalendarsResponse;

export const useGetCalendarsForMember: (
  memberId: string
) => UseGetGoogleCalendarsResponse = (memberId) => {
  const {
    organizationAccounts: { integrations },
  } = useAuth();
  return useMemo(() => {
    if (!integrations?.calendars?.length)
      return { accounts: [], defaultWriteCalendar: null };
    return getAllCalendarAccounts(
      integrations.calendars.filter(
        (calendar) => calendar.memberId === memberId
      )
    );
  }, [integrations?.calendars, memberId]);
};

export const useGetCalendars: UseGetGoogleCalendarsType = (
  accountOnly = false
) => {
  const {
    organizationAccounts: { integrations },
    aid,
  } = useAuth();
  return useMemo(() => {
    if (!integrations?.calendars?.length)
      return { accounts: [], defaultWriteCalendar: null };
    if (accountOnly)
      return getAllCalendarAccounts(
        integrations.calendars.filter((calendar) => calendar.memberId === aid)
      );
    return getAllCalendarAccounts(integrations.calendars);
  }, [integrations?.calendars, accountOnly, aid]);
};

export function useGetZoomAccounts(accountOnly = false) {
  const {
    organizationAccounts: { integrations },
    aid,
  } = useAuth();
  return useMemo(() => {
    if (!integrations?.zoom?.length) return { accounts: [] };
    if (accountOnly)
      return {
        accounts: integrations.zoom.filter((zoom) => zoom.memberId === aid),
      };
    return { accounts: integrations.zoom };
  }, [integrations?.zoom, accountOnly, aid]);
}
